import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/dont-fear-failure-learn-from-it",
  "title": "Don’t Fear Failure, Learn From It",
  "excerpt": "You haven’t truly failed if you’ve learned something",
  "tags": ["rejection", "personal development", "mental health", "self improvement"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/blog-covers/thought-catalog-89JYqPG7P94-unsplash_mz3lim.jpg",
    "originalHeight": 3456,
    "originalWidth": 5184,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "draft": false,
  "type": "blog",
  "date": "2018-01-31T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This was a something my teacher used to tell me when I was in high school, which was a vocational school. My major was Electronics and sometimes I’d finish a project and it didn’t work. I’d get down on myself, because I have an unhealthy obsession with being perfect (that’s a different article entirely). I would lose confidence and it would impact my other projects. All because one project had failed. Regardless of the fact that I did countless projects and they weren’t all failures, a lot were successful. This mentally didn’t make sense.`}</p>
    <p>{`He used to tell me this to reiterate what I now know to be true. No one is perfect the first time at something. If you’re learning a new skill or starting a new job, mistakes and failure will happen. It’s not something that should be blown up to the proportion of “I’m a fuck up, what am I doing here”. You need to remain calm and think through what went wrong and mark what needs to be changed/avoided. It only becomes a problem if you let that mistake go and not learn anything from it.`}</p>
    <p>{`For me, I like to write corrections down so I will remember and not make them again. Example, if I’m working with a new editor and they want their articles formatted in a certain way. I write their specific instructions down when they make corrections. I’ve made a mistake but I will strive to not make it again and either memorize it or keep the paper near for reference.`}</p>
    <p>{`I’m not saying go and make all the mistakes humanly possible. I mean you could do that but that’s a tough road to travel. Strive to do your best and when you mess up, because everyone will at some point. Especially if you’re doing something new, remain calm and think through the problem. You know you can do this and you’ll make it out the other side.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      